import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/dev-site/dev-site/src/templates/Page/index.ts";
import BinaryDatabases from './_binary-databases.mdx';
import ConversionUtility from './_conversion-utility.mdx';
import CsvDatabasesIntro from './_csv-databases-intro.mdx';
import DataPrivacyWarning from './_data-privacy-warning.mdx';
import ExampleFiles from './_example-files.mdx';
import FileFormat from './_file-format.mdx';
import APIs from './_apis.mdx';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const ZipFileContent = makeShortcode("ZipFileContent");
const CsvFileExamples = makeShortcode("CsvFileExamples");
const MmdbFileExamples = makeShortcode("MmdbFileExamples");
const DatabaseSizes = makeShortcode("DatabaseSizes");
const DatabaseChanges = makeShortcode("DatabaseChanges");
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...props} components={components} mdxType="MDXLayout">
    <p>{`Determine the Internet Service Provider, organization name, and autonomous
system organization and number associated with an IP address.`}</p>
    <p>{`To learn more about the GeoIP2 ISP database, including terms and pricing, please
`}
      <a {...{
        "href": "https://www.maxmind.com/en/geoip2-isp-database",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer",
        "parentName": "p"
      }}>{`visit the GeoIP2 ISP Database page`}</a>
      {`.`}</p>
    <DataPrivacyWarning mdxType="DataPrivacyWarning" />
    <div {...{
      "id": "toc-binary-database"
    }}><h2 {...{
        "id": "binary-database",
        "parentName": "div"
      }}>{`Binary Database`}</h2>
      <BinaryDatabases mdxType="BinaryDatabases" />
      <table {...{
        "parentName": "div"
      }}>

        <thead {...{
          "parentName": "table"
        }}>

          <tr {...{
            "parentName": "thead"
          }}>

            <th {...{
              "parentName": "tr"
            }}>{`Language or Framework`}</th>


            <th {...{
              "parentName": "tr"
            }}>{`Documentation`}</th>


            <th {...{
              "parentName": "tr"
            }}>{`Example`}</th>

          </tr>

        </thead>


        <tbody {...{
          "parentName": "table"
        }}>

          <tr {...{
            "parentName": "tbody"
          }}>

            <td {...{
              "parentName": "tr"
            }}>{`.NET (C#)`}</td>


            <td {...{
              "parentName": "tr"
            }}><a {...{
                "href": "https://github.com/maxmind/GeoIP2-dotnet#database-usage",
                "target": "_blank",
                "rel": "nofollow noopener noreferrer",
                "parentName": "td"
              }}>{`GitHub - Database Usage`}</a></td>


            <td {...{
              "parentName": "tr"
            }}><a {...{
                "href": "https://github.com/maxmind/GeoIP2-dotnet#isp-database",
                "target": "_blank",
                "rel": "nofollow noopener noreferrer",
                "parentName": "td"
              }}>{`ISP Example`}</a></td>

          </tr>


          <tr {...{
            "parentName": "tbody"
          }}>

            <td {...{
              "parentName": "tr"
            }}>{`Java`}</td>


            <td {...{
              "parentName": "tr"
            }}><a {...{
                "href": "https://github.com/maxmind/GeoIP2-java#database-usage",
                "target": "_blank",
                "rel": "nofollow noopener noreferrer",
                "parentName": "td"
              }}>{`GitHub - Database Usage`}</a></td>


            <td {...{
              "parentName": "tr"
            }}><a {...{
                "href": "https://github.com/maxmind/GeoIP2-java#isp",
                "target": "_blank",
                "rel": "nofollow noopener noreferrer",
                "parentName": "td"
              }}>{`ISP Example`}</a></td>

          </tr>


          <tr {...{
            "parentName": "tbody"
          }}>

            <td {...{
              "parentName": "tr"
            }}>{`Node.js`}</td>


            <td {...{
              "parentName": "tr"
            }}><a {...{
                "href": "https://github.com/maxmind/GeoIP2-node#database-usage",
                "target": "_blank",
                "rel": "nofollow noopener noreferrer",
                "parentName": "td"
              }}>{`GitHub - Database Usage`}</a></td>


            <td {...{
              "parentName": "tr"
            }}><a {...{
                "href": "https://github.com/maxmind/GeoIP2-node#isp-example",
                "target": "_blank",
                "rel": "nofollow noopener noreferrer",
                "parentName": "td"
              }}>{`ISP Example`}</a></td>

          </tr>


          <tr {...{
            "parentName": "tbody"
          }}>

            <td {...{
              "parentName": "tr"
            }}>{`PHP`}</td>


            <td {...{
              "parentName": "tr"
            }}><a {...{
                "href": "https://github.com/maxmind/GeoIP2-php#database-reader",
                "target": "_blank",
                "rel": "nofollow noopener noreferrer",
                "parentName": "td"
              }}>{`GitHub - Database Reader`}</a></td>


            <td {...{
              "parentName": "tr"
            }}><a {...{
                "href": "https://github.com/maxmind/GeoIP2-php#isp-example",
                "target": "_blank",
                "rel": "nofollow noopener noreferrer",
                "parentName": "td"
              }}>{`ISP Example`}</a></td>

          </tr>


          <tr {...{
            "parentName": "tbody"
          }}>

            <td {...{
              "parentName": "tr"
            }}>{`Python`}</td>


            <td {...{
              "parentName": "tr"
            }}><a {...{
                "href": "https://github.com/maxmind/GeoIP2-python#database-usage",
                "target": "_blank",
                "rel": "nofollow noopener noreferrer",
                "parentName": "td"
              }}>{`GitHub - Database Usage`}</a></td>


            <td {...{
              "parentName": "tr"
            }}><a {...{
                "href": "https://github.com/maxmind/GeoIP2-python#isp-database",
                "target": "_blank",
                "rel": "nofollow noopener noreferrer",
                "parentName": "td"
              }}>{`ISP Example`}</a></td>

          </tr>


          <tr {...{
            "parentName": "tbody"
          }}>

            <td {...{
              "parentName": "tr"
            }}>{`Ruby`}</td>


            <td {...{
              "parentName": "tr"
            }}><a {...{
                "href": "https://github.com/maxmind/GeoIP2-ruby#database-reader",
                "target": "_blank",
                "rel": "nofollow noopener noreferrer",
                "parentName": "td"
              }}>{`GitHub - Database Reader`}</a></td>


            <td {...{
              "parentName": "tr"
            }}><a {...{
                "href": "https://github.com/maxmind/GeoIP2-ruby#isp-example",
                "target": "_blank",
                "rel": "nofollow noopener noreferrer",
                "parentName": "td"
              }}>{`ISP Example`}</a></td>

          </tr>

        </tbody>

      </table></div>
    <div {...{
      "id": "toc-csv-database"
    }}><h2 {...{
        "id": "csv-database",
        "parentName": "div"
      }}>{`CSV Database`}</h2>
      <CsvDatabasesIntro mdxType="CsvDatabasesIntro" />
      <ZipFileContent productName="GeoIP2-ISP" mdxType="ZipFileContent" />
      <FileFormat mdxType="FileFormat" />
      <div {...{
        "id": "toc-blocks-files",
        "parentName": "div"
      }}><h3 {...{
          "id": "blocks-files",
          "parentName": "div"
        }}>{`Blocks Files`}</h3>
        <p {...{
          "parentName": "div"
        }}>{`There are two CSV files for network blocks, one each for IPv4 and IPv6 blocks.
These are named `}
          <inlineCode {...{
            "parentName": "p"
          }}>{`GeoIP2-ISP-Blocks-IPv4.csv`}</inlineCode>
          {` and `}
          <inlineCode {...{
            "parentName": "p"
          }}>{`GeoIP2-ISP-Blocks-IPv6.csv`}</inlineCode>
          {`
respectively.`}</p>
        <table>
          <tbody>
            <tr>
              <th>
                <p>{`Name`}</p>
              </th>
              <th>
                <p>{`Type`}</p>
              </th>
              <th>
                <p>{`Description`}</p>
              </th>
            </tr>
            <tr>
              <td>
                <p>{`network`}</p>
              </td>
              <td>
                <p>{`IP network as a string`}</p>
              </td>
              <td>
                <p>{`This is the IPv4 or IPv6 network in CIDR format such as
“2.21.92.0/29” or “2001:4b0::/80”. We offer a utility to convert
this column to start/end IPs or start/end integers. See `}
                  <a href="#conversion-utility">{`the conversion utility section`}</a>
                  {` for
details.`}</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>{`isp`}</p>
              </td>
              <td>
                <p>{`string`}</p>
              </td>
              <td>
                <p>{`The name of the ISP associated with the IP address.`}</p>
                <p>{`*`}
                  {` `}
                  <a href="https://support.maxmind.com/hc/en-us/articles/4408200231067-IP-Network-Data#h_01FN989KHXR7TGXPB5T2DK0Q77" target="_blank" rel="nofollow noopener noreferrer">{`Learn more about ISP data on our Knowledge Base.`}</a></p>
              </td>
            </tr>
            <tr>
              <td>
                <p>{`organization`}</p>
              </td>
              <td>
                <p>{`string`}</p>
              </td>
              <td>
                <p>{`The name of the organization associated with the IP address.`}</p>
                <p>{`*`}
                  {` `}
                  <a href="https://support.maxmind.com/hc/en-us/articles/4408200231067-IP-Network-Data#h_01FN989KHXR7TGXPB5T2DK0Q77" target="_blank" rel="nofollow noopener noreferrer">{`Learn more about organization data on our Knowledge Base.`}</a></p>
              </td>
            </tr>
            <tr>
              <td>
                <p>{`autonomous_system_number`}</p>
              </td>
              <td>
                <p>{`integer`}</p>
              </td>
              <td>
                <p>{`The `}
                  <a href="https://en.wikipedia.org/wiki/Autonomous_system_(Internet)">{`autonomous system`}</a>
                  {` number associated with the IP address.`}</p>
                <p>{`*`}
                  {` `}
                  <a href="https://support.maxmind.com/hc/en-us/articles/4408200231067-IP-Network-Data#h_01FN989WWSE56YD6AV4QSPSWDW" target="_blank" rel="nofollow noopener noreferrer">{`Learn more about autonomous system data on our Knowledge Base.`}</a></p>
              </td>
            </tr>
            <tr>
              <td>
                <p>{`autonomous_system_organization`}</p>
              </td>
              <td>
                <p>{`string`}</p>
              </td>
              <td>
                <p>{`The organization associated with the registered autonomous system
number for the IP address.`}</p>
                <p>{`*`}
                  {` `}
                  <a href="https://support.maxmind.com/hc/en-us/articles/4408200231067-IP-Network-Data#h_01FN989WWSE56YD6AV4QSPSWDW" target="_blank" rel="nofollow noopener noreferrer">{`Learn more about autonomous system data on our Knowledge Base.`}</a></p>
              </td>
            </tr>
            <tr>
              <td>
                <p>{`mobile_country_code`}</p>
              </td>
              <td>
                <p>{`string`}</p>
              </td>
              <td>
                <p>{`The `}
                  <a {...{
                    "href": "https://en.wikipedia.org/wiki/Mobile_country_code",
                    "target": "_blank",
                    "rel": "nofollow noopener noreferrer",
                    "parentName": "p"
                  }}>{`mobile country code
(MCC)`}</a>
                  {` associated
with the IP address and ISP.`}</p>
                <p>{`*`}
                  {` `}
                  <a href="https://support.maxmind.com/hc/en-us/articles/4408200231067-IP-Network-Data#h_01FT6Y6ANRH9GWYXE78B4RXAEX" target="_blank" rel="nofollow noopener noreferrer">{`Learn more about mobile country codes on our Knowledge Base.`}</a></p>
              </td>
            </tr>
            <tr>
              <td>
                <p>{`mobile_network_code`}</p>
              </td>
              <td>
                <p>{`string`}</p>
              </td>
              <td>
                <p>{`The `}
                  <a {...{
                    "href": "https://en.wikipedia.org/wiki/Mobile_country_code",
                    "target": "_blank",
                    "rel": "nofollow noopener noreferrer",
                    "parentName": "p"
                  }}>{`mobile network code
(MNC)`}</a>
                  {` associated
with the IP address and ISP.`}</p>
                <p>{`*`}
                  {` `}
                  <a href="https://support.maxmind.com/hc/en-us/articles/4408200231067-IP-Network-Data#h_01FT6Y6ANRH9GWYXE78B4RXAEX" target="_blank" rel="nofollow noopener noreferrer">{`Learn more about mobile network codes on our Knowledge Base.`}</a></p>
              </td>
            </tr>
          </tbody>
        </table></div>
      <div {...{
        "id": "toc-conversion-utility",
        "parentName": "div"
      }}><h3 {...{
          "id": "conversion-utility",
          "parentName": "div"
        }}>{`Conversion Utility`}</h3>
        <ConversionUtility mdxType="ConversionUtility" /></div></div>
    <div {...{
      "id": "toc-example-files"
    }}><h2 {...{
        "id": "example-files",
        "parentName": "div"
      }}>{`Example Files`}</h2>
      <ExampleFiles mdxType="ExampleFiles" />
      <div {...{
        "id": "toc-csv-example-files",
        "parentName": "div"
      }}><h3 {...{
          "id": "csv-example-files",
          "parentName": "div"
        }}>{`CSV Example Files`}</h3>
        <CsvFileExamples files={[{
          filename: 'GeoIP2-ISP-CSV_Example.zip',
          link: '/static/GeoIP2-ISP-CSV_Example.zip'
        }]} mdxType="CsvFileExamples" /></div>
      <div {...{
        "id": "toc-mmdb-example-files",
        "parentName": "div"
      }}><h3 {...{
          "id": "mmdb-example-files",
          "parentName": "div"
        }}>{`MMDB Example Files`}</h3>
        <MmdbFileExamples files={[{
          filename: 'GeoIP2-ISP-Test.mmdb',
          link: 'https://github.com/maxmind/MaxMind-DB/blob/main/test-data/GeoIP2-ISP-Test.mmdb'
        }]} mdxType="MmdbFileExamples" /></div></div>
    <div {...{
      "id": "toc-database-sizes"
    }}><h2 {...{
        "id": "database-sizes",
        "parentName": "div"
      }}>{`Database Sizes`}</h2>
      <DatabaseSizes dateRange="March to June 2024" databaseChanges={[{
        databaseName: 'GeoIP ISP',
        csvSizeRange: '80.1 MB - 83.4 MB',
        mmdbSizeRange: '14.5 MB - 14.8 MB',
        ipv4Range: '715,000 - 739,000',
        ipv6Range: '248,000 - 264,000'
      }]} mdxType="DatabaseSizes" /></div>
    <div {...{
      "id": "toc-database-changes"
    }}><h2 {...{
        "id": "database-changes",
        "parentName": "div"
      }}>{`Database Changes`}</h2>
      <DatabaseChanges product="ISP" mdxType="DatabaseChanges" /></div>
    <div {...{
      "id": "toc-apis-and-third-party-integrations"
    }}><h2 {...{
        "id": "apis-and-third-party-integrations",
        "parentName": "div"
      }}>{`APIs and Third-Party Integrations`}</h2>
      <APIs mdxType="APIs" /></div>

  </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      